import React from 'react'
import { ReactNode, useEffect } from 'react'
import { trackEvent } from 'utils/analytics-helpers'

const getEventDataForTarget = (target: HTMLElement) => {
  return {
    elementType: target.tagName.toLowerCase(),
    elementId: target.id || 'unknown',
    elementName: target.getAttribute('name') || 'unknown',
    elementValue: (target as HTMLInputElement | HTMLSelectElement).value || '',
  }
}

const trackEventIfElementHasTrackAttribute = (
  eventType: 'Element Focus' | 'Element Blur',
  target: HTMLElement,
): void => {
  if (target.hasAttribute('data-track')) {
    trackEvent(eventType, getEventDataForTarget(target))
  }
}

/**
 * FieldFocusAndBlurTracker
 * This component listens for focus and blur events on form fields and tracks them to the analytics services.
 * It is opt-in, and only fields with the `data-track` attribute will be logged.
 */
export const FieldFocusAndBlurTracker = ({
  children,
}: {
  children: ReactNode
}) => {
  useEffect(() => {
    const handleFocus = (event: FocusEvent): void => {
      const target = event.target as HTMLElement
      trackEventIfElementHasTrackAttribute('Element Focus', target)
    }

    const handleBlur = (event: FocusEvent): void => {
      const target = event.target as HTMLElement
      trackEventIfElementHasTrackAttribute('Element Blur', target)
    }

    document.addEventListener('focus', handleFocus, true)
    document.addEventListener('blur', handleBlur, true)

    return () => {
      document.removeEventListener('focus', handleFocus, true)
      document.removeEventListener('blur', handleBlur, true)
    }
  }, [])

  return <>{children}</>
}
