import * as amplitude from '@amplitude/analytics-browser'

declare global {
  interface Window {
    FS: {
      event: (eventName: string, body: any) => void
    }
  }
}

const logFullStoryCustomEvent = (eventName: string, body?: any) => {
  if (window.FS) {
    window.FS.event(eventName, body)
  }

  return null
}

export const trackEvent = (eventName: string, data?: any) => {
  logFullStoryCustomEvent(eventName, data)
  amplitude.track(eventName, data)
}

export const trackPurchase = (purchaseAmount: number) => {
  trackEvent('purchase', { purchaseAmount })

  if (purchaseAmount) {
    const amplitudePurchaseEvent = new amplitude.Revenue().setPrice(
      purchaseAmount,
    )
    amplitude.revenue(amplitudePurchaseEvent)
  }
}
