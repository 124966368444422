import { SchemeEnum } from '@enums'
import VehicleReg from 'components/VehicleReg'
import {
  COMMERCIAL_VEHICLE_SCHEME,
  COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT,
  IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME,
  IMPOUNDED_PRIVATE_CAR_SCHEME,
  MOTORCYCLE,
  MOTORCYCLE_VEHICLE_SCHEME,
  PRIVATE_CAR_SCHEME,
  VEHICLE_LEARNER_SCHEME,
} from 'containers/Vehicle/constants'
import VehicleModal from 'containers/VehicleModal'
import { useScheme, useVehicle } from 'hooks'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useCustomerAccount from 'hooks/useCustomerAccount'
import { VehicleValue } from 'components/VehicleValue'
import { updateVehicleValue } from 'containers/Vehicle/actions'
import { updateRiskData } from 'containers/RiskDataCollectionPage/actions'
import { useDispatch } from 'react-redux'
import { StyledSummary } from './VehicleSummary.styles'

interface VehicleSummaryProps {
  id: string
  handleUpdate: () => void
  isIndividual?: boolean
  hideValue?: boolean
}

const VehicleSummary: React.FC<VehicleSummaryProps> = ({
  id,
  handleUpdate,
  isIndividual = false,
  hideValue = false,
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const { vehicle, vehicleValue } = useVehicle()
  const { scheme } = useScheme()
  const history = useHistory()

  const { isCustomerAccountQuote } = useCustomerAccount()

  const registrationNumber = vehicle.selected.RegistrationNumber || ''

  const vehicleSummary = useMemo(() => {
    switch (scheme) {
      case PRIVATE_CAR_SCHEME:
        if (vehicle.selected.Variant) {
          return `${vehicle.selected.Variant}`
        }
        return `${vehicle.selected.Make} ${vehicle.selected.Model}`

      case COMMERCIAL_VEHICLE_SCHEME:
      case SchemeEnum.COURIER:
        if (
          vehicle.selected.GrossWeight &&
          Number(vehicle.selected.GrossWeight) >
            COMMERCIAL_VEHICLE_WEIGHT_BREAKPOINT
        ) {
          return `Weight: ${vehicle.selected.GrossWeight}kg Year: ${vehicle.selected.YearOfManufacture}`
        }
        if (vehicle.selected.Variant) {
          return `${vehicle.selected.Variant}`
        }
        return `${vehicle.selected.Make} ${vehicle.selected.Model}`

      case VEHICLE_LEARNER_SCHEME:
      case IMPOUNDED_COMMERCIAL_VEHICLE_SCHEME:
      case IMPOUNDED_PRIVATE_CAR_SCHEME:
      case MOTORCYCLE_VEHICLE_SCHEME:
        if (vehicle.selected.Variant) {
          return `${vehicle.selected.Variant}`
        }
        return `${vehicle.selected.Make} ${vehicle.selected.Model}`

      default:
        return ''
    }
  }, [vehicle, scheme])

  const toggleVehicleModal = () => {
    if (isCustomerAccountQuote) {
      window.location.href = `/quote/driver-details/logged-in${history.location.search}`
    } else {
      setModalVisible(!isModalVisible)
    }
  }

  const onUpdate = () => {
    handleUpdate()
    toggleVehicleModal()
  }

  const dispatch = useDispatch()

  const handleVehicleValueChange = (value: any) => {
    dispatch(
      updateVehicleValue({
        value: value.target.value,
        description: 'VehicleValue',
      }),
    )
  }

  // TODO: Revisit this, at the minute we are just
  // reusing logic from before to store descriptions in redux.
  const handleSelect = (name: string, value: string, label: string) => {
    // We need to capitalise first letter, as in state they are using capitals at the start
    const capitalisedName = name.charAt(0).toUpperCase() + name.slice(1)

    switch (capitalisedName) {
      case 'VehicleValue':
        return dispatch(
          updateVehicleValue({
            value,
            description: label,
          }),
        )
      default:
        return dispatch(
          updateRiskData({
            [capitalisedName]: {
              value,
              description: label,
            },
          }),
        )
    }
  }

  return (
    <>
      <StyledSummary
        id={id}
        onClick={toggleVehicleModal}
        isIndividual={isIndividual}
      >
        <p className='quote-summary-title vehicle'>Vehicle to be covered</p>

        <p className='quote-summary-text vehicle' data-tracking-scheme={scheme}>{vehicleSummary}</p>
        <VehicleReg reg={registrationNumber} />
        <i className='fa fa-pencil-alt' />
        {!hideValue && (
          <div>
            <p style={{ marginBottom: 0 }}>Vehicle value</p>
            <VehicleValue
              onSelect={handleSelect}
              onChange={handleVehicleValueChange}
              value={vehicleValue}
              isMotorcycle={vehicle.selected.VehicleType === MOTORCYCLE}
            />
          </div>
        )}
      </StyledSummary>
      {!isCustomerAccountQuote && (
        <VehicleModal
          isVisible={isModalVisible}
          handleUpdate={onUpdate}
          hideModal={toggleVehicleModal}
        />
      )}
      {/* {isCustomerAccountQuote && (
        <VehicleCarouselModal
          key='VehicleCarouselModalComponent'
          isVisible={isModalVisible}
          hideModal={toggleVehicleModal}
        />
      )} */}
    </>
  )
}
export default VehicleSummary
