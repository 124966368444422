import { AmplitudeWrapper } from 'components/Analytics/AmplitudeWrapper'
import React, { ReactNode } from 'react'
import { FieldFocusAndBlurTracker } from './FieldFocusAndBlurTracker'

export const AnalyticsWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <AmplitudeWrapper>
      <FieldFocusAndBlurTracker>{children}</FieldFocusAndBlurTracker>
    </AmplitudeWrapper>
  )
}
